import Vue from 'vue'
import VueRouter from 'vue-router'
import Page404 from '../views/404'

Vue.use(VueRouter)

const routes = [
  {
    name: '404',
    path: '/404',
    component: Page404
  }
]

const createRouter = () => new VueRouter({
  mode: 'history',
  routes // (缩写) 相当于 routes: routes
})

const router = createRouter()

export const otherRoutes = [
  {
    name: '404',
    path: '*',
    component: Page404
  }
]

/**
 * reset the router
 * https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
 */
export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // the relevant part
}

export default router
