<template>
  <div id="subapp-container">
    <div id="nav" class='clearfix'>
      <a id='logo' href='/'>
        <img src="../public/logo.svg" alt="logo" />
      </a>
      <div id='nav-links'>
        <template v-for='(item) in links'>
          <router-link :key='item.label' :to="{ path: item.link }">{{ item.label }}</router-link>
        </template>
      </div>
      <div id='nav-user' v-if='isLogin'>
        <a class='official-link' href='http://www.eoeocloud.com' target="_blank">官网</a>
        <a class='console-link' v-if='!isConsole' href='/adp?m=cs#/controller/project/list' target="_blank">控制台</a>
        <!-- <img :src="user.avatar" alt="avatar" /> -->
        <span class='welcome'>欢迎您</span>
        <a :href="uaaUrl ? uaaUrl : 'javascript:;'" target="_uaa">{{ user.realname || user.username || user.account }}</a>
        <a class='user-logout' href="javascript:;" v-if='isLogin' @click='logout'>退出</a>
      </div>
      <div id='nav-login' v-else>
        <a class='official-link' href='http://www.eoeocloud.com' target="_blank">官网</a>
        <a class='console-link' href='/adp?m=cs#/controller/project/list' target="_blank">控制台</a>
        <a href="javascript:;" @click='login'>登录</a>
        <a href="javascript:;" @click="register">注册</a>
      </div>
    </div>
    <div v-show='!loading' id='subapp-viewport'>
    </div>
    <div class='loading' v-if='loading'>
      加载中，请稍后...
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Channel from './channel'

export default {
  mixins: [
    Channel
  ],
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      user: {}
    }
  },
  computed: {
    isLogin () {
      return this.loginIn && !!this.user.openid
    },
    query () {
      var queryStr = location.search.slice(1)
      var queryArr = queryStr.split("&")
      var query = {}
			for (var i = 0; i < queryArr.length; i++) {
				query[queryArr[i].split("=")[0]] = queryArr[i].split("=")[1];
      }
      return query
    },
    isConsole () {
      return this.isLogin && this.query.m === 'cs'
    },
    uaaUrl () {
      return process.env.VUE_APP_UAA_URL
    },
    links () {
      return this.isConsole ?
      [
        {
          link: '/adp?m=cs#/controller/project/list',
          label: '应用开发平台'
        },
        {
          link: '/app?m=cs#/user-center/apply',
          label: '云应用市场'
        },
        {
          link: '/da?m=cs#/ModelList?tabPane=all',
          label: '数据分析建模平台'
        },
        {
          link: '/sdi?m=cs#/',
          label: '系统数据集成'
        }
      ] : [
        {
          link: '/adp/#/',
          label: '应用开发平台'
        },
        {
          link: '/app/#/',
          label: '云应用市场'
        }
      ]
    }
  },
  watch: {
    loading () {
      console.log('loading change', this.loading)
    },
    loginIn () {
      if (this.loginIn && !this.isLogin) {
        this.requestUserInfo(false)
      }
    }
  },
  async beforeMount () {
    // this.requestUserInfo(true)
  },
  methods: {
    async requestUserInfo (notice) {
      try {
        const token = window.localStorage.getItem('token')
        this.user = await this.getUserInfo(token)
        console.log('用户信息:', this.user)
        notice && this.noticeTokenChange(token)
      } catch (err) {
        console.log('获取用户信息失败:', err.message)
        notice && this.noticeTokenChange(null)
      }
    },
    async getUserInfo (token) {
      if (!token) {
        throw new Error('未登录')
      }
      const { status, data } = await axios.get(`${process.env.VUE_APP_UAA_URL}/api/user/info`, {
        params: {
          access_token: token
        },
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
      if (status !== 200) {
        throw new Error('未登录')
      }
      console.log('user info:', data)
      return data
    },
    register () {
      window.location.replace(`${process.env.VUE_APP_UAA_URL}/#/sign-in?redirect=${encodeURIComponent(window.location.href)}`)
    },
    login () {
      window.location.replace(`${process.env.VUE_APP_UAA_URL}/oauth/authorize?response_type=token&client_id=${process.env.VUE_APP_UAA_CLIENT_ID}&scope=user_info&redirect_uri=${window.location.protocol}//${window.location.host}&state=${encodeURIComponent(window.location.href)}`)
    },
    logout () {
      window.localStorage.clear()
      this.noticeTokenChange(null)
      window.location.replace(`${process.env.VUE_APP_UAA_URL}/api/logout?redirect=${encodeURIComponent(window.location.href)}`)
    }
  }
}
</script>


<style lang="less">
@import './styles/vars.less';

*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif!important;
  font-size: 14px;
  line-height: 1.5;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav {
  height: @header-height;
  line-height: @header-height;
  z-index: 100;
  position: fixed;
  width: 100%;
  padding: 0 16px;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.06);
  background-color: #fff;
  color: #333;
  font-size: 12px!important;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  a {
    font-weight: bold;
    color: inherit;
    outline: 0;
    text-decoration: none;
    font-weight: 400;
    letter-spacing: .5px;
    &.router-link-exact-active, &.router-link-active {
      // color: #FFFFFF;
      font-weight: 500;
    }

    &:active, &:hover {
      outline: 0;
      text-decoration: none;
      // color: #FFFFFF;
    }
  }
  .console-link, .official-link {
    margin-right: 15px;
  }
}

#logo {
  margin-left: 14px;
  position: relative;
  top: -3px;
  img {
    border: 0;
    vertical-align: middle;
  }
}

#nav-links {
  margin-left: 80px;
  display: inline-block;
  > a {
    margin-right: 32px;
  }
}
#nav-user, #nav-login {
  float: right;
  // color: #fff;
  .welcome {
    margin-right: 8px;
  }
  a {
    margin-left: 15px;
  }
}

#nav-user {
  img {
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid #B8C1E2;
    vertical-align: middle;
    margin-right: 12px;
    overflow: hidden;
  }
  .user-logout {
    margin-left: 33px;
    display: inline-block;
  }
}

#subapp-viewport {
  padding-top: @header-height;
}

.loading {
  width: 100vw;
  // height: calc(100vh - 42px);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: #aaa;
  background: #f5f8fa;
}

.clearfix {
  zoom: 1;
  &:after {
    display: block;
    clear: both;
    content: "";
    visibility: hidden; 
    height: 0;
  } 
}

@import './styles/adp.less';
@import './styles/app.less';
@import './styles/da.less';
@import './styles/sdi.less';
</style>
