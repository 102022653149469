import 'whatwg-fetch' // fetch polyfill
// import 'proxy-polyfill' // proxy polyfill
import 'custom-event-polyfill' // custom event polyfill
import 'core-js/stable/promise';
import 'core-js/stable/symbol';
import 'core-js/stable/string/starts-with';
import 'core-js/web/url';

(function () { // custom event polyfill
  if (
  typeof window.CustomEvent === "function" ||
  // In Safari, typeof CustomEvent == 'object' but it otherwise works fine
  window.CustomEvent.toString().indexOf('CustomEventConstructor')>-1
  ) { return; }
  
  function CustomEvent ( event, params ) {
  params = params || { bubbles: false, cancelable: false, detail: undefined };
  var evt = document.createEvent( 'CustomEvent' );
  evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
  return evt;
  }
  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;
})();

import Vue from 'vue'
import { registerMicroApps, setDefaultMountApp, start } from 'qiankun'

import App from './App.vue'
import router, { resetRouter } from './router'

Vue.config.productionTip = false

const hackLessProblem = function () {
  const head = document.getElementsByTagName('head')[0]
  const styles = document.getElementsByTagName('style')
  for (let i = 0; i < styles.length; i++) {
    const style = styles[i]
    if (style.innerText === 'body { display: none !important }') {
      head.removeChild(style)
    }
  }
  console.log('hacked!')
}

let app = null

const loader = (loading) => {
  if (!app) {
    app = new Vue({
      router,
      data() {
        return {
          loading
        }
      },
      render () {
        return <App propsLoading={this.loading} />
      }
    }).$mount('#subapp-container')
  } else {
    app.loading = loading
  }
}

// 初始化应用
loader(true)

const apps = [
  {
    name: 'adp',
    entry: process.env.VUE_APP_ADP_URL,
    container: '#subapp-viewport',
    loader: loader,
    activeRule: '/adp'
  },
  {
    name: 'app',
    entry: process.env.VUE_APP_SAAS_URL,
    container: '#subapp-viewport',
    loader: loader,
    activeRule: '/app'
  },
  {
    name: 'da',
    entry: process.env.VUE_APP_DA_URL,
    container: '#subapp-viewport',
    loader: loader,
    activeRule: '/da'
  },
  {
    name: 'sdi',
    entry: process.env.VUE_APP_SDI_URL,
    container: '#subapp-viewport',
    loader: loader,
    activeRule: '/sdi'
  },
]

const childrenPath = apps.map(item => item.activeRule)

router.beforeEach((to, from, next) => {
  if (to.name) { // 有 name 属性，说明是主项目的路由
    return next()
  }
  if (childrenPath.some(item => to.path.includes(item))){
    return next()
  }
  next({ name: '404' })
})

registerMicroApps(apps, {
  afterMount () {
    hackLessProblem()
  },
  afterUnmount () {
    resetRouter()
  }
})

const pathname = window.location.pathname
const _app = apps.filter(d => d.activeRule === pathname).pop()

setDefaultMountApp(_app ? _app.activeRule : apps[0].activeRule)

start({
  // prefetch: true,
  // standbox: true
})
