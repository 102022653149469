export default {
  data () {
    return {
      iframe: null,
      iframeLoaded: false,
      loginIn: false,
      origin: null,
      interval: null
    }
  },
  beforeMount () {
    this.origin = `${window.location.protocol}${process.env.VUE_APP_BRIDGE_URL}`
    this.refreshToken()
    this.interval = setInterval(this.refreshToken, 200)

    const iframe = document.createElement('iframe')
    iframe.style.position = 'absolute'
    iframe.style.display = 'none'
    iframe.onload = this.handleOnLoad.bind(this)
    iframe.src = `${this.origin}/bridge/proxy.html`
    document.body.appendChild(iframe)
    this.iframe = iframe
  },
  beforeDestroy () {
    this.interval && clearInterval(this.interval)
  },
  methods: {
    refreshToken () {
      const token = window.localStorage.getItem('token')
      this.loginIn = !!token
    },
    noticeTokenChange (token) {
      if (this.origin && this.iframeLoaded) {
        this.iframe.contentWindow.postMessage(token ? `set:${token}` : '', this.origin)
      }
    },
    handleOnLoad () {
      if (!this.iframeLoaded) {
        this.iframeLoaded = true
      }
      window.addEventListener('message', (evt) => {
        if (typeof evt.data === 'string' && evt.data && evt.data.startsWith('set:')) {
          // console.log('询问结果: ', evt.data)
          window.localStorage.setItem('token', evt.data.slice(4))
          this.refreshToken()
        }
      }, false);
      if (!this.loginIn) { // 没登录，问隔壁登录了没
        this.iframe.contentWindow.postMessage('get', this.origin)
      } else {
        this.noticeTokenChange(window.localStorage.getItem('token'))
      }
    }
  }
}
